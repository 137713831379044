import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  connect() {
    this.reload_when_changes_are_pending()
  }

  reload_when_changes_are_pending() {
    if (this.data.get("pending-change") === "true") {
      // sleep then reload the page when there is any pending change
      setTimeout(() => {
        window.location.reload()
      }, 5000)
    }
  }
}
